import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import SEO from '../components/organisms/seo';
import ArticlePageModules from '../components/organisms/articlePageModules';

const TeamMalawiPage = ({ data }) => {
  const { content, shortcuts } = data.team.nodes[0];

  return (
    <div className="spacing-top-big">
      <SEO siteTitle="Team" />
      <ArticlePageModules
        content={content}
        shortcuts={shortcuts}
        showPersonsModule
      />
    </div>
  );
};

export const query = graphql`
  query TeamMalawi {
    team: allSanityTeamMalawi {
      nodes {
        content {
          ... on SanityList {
            title
            listItem {
              ... on SanityPerson {
                name
                jobTitle
                image {
                  image {
                    asset {
                      fluid(maxWidth: 3600) {
                        ...GatsbySanityImageFluid
                      }
                    }
                  }
                  alt
                }
                description
                linkedinUrl
                mailAddress
              }
            }
          }
          ... on SanityMediaCopy {
            type
            media {
              ... on SanityMainImage {
                alt
                image {
                  asset {
                    fluid(maxWidth: 3600) {
                      ...GatsbySanityImageFluid
                    }
                  }
                }
              }
              ... on SanityVideo {
                videoFile {
                  asset {
                    url
                  }
                }
              }
            }
            overline
            _rawCopy
          }
          ... on SanityCollage {
            type
            big {
              ... on SanityMainImage {
                alt
                image {
                  asset {
                    fluid(maxWidth: 3600) {
                      ...GatsbySanityImageFluid
                    }
                  }
                }
              }
              ... on SanityVideo {
                videoFile {
                  asset {
                    url
                  }
                }
              }
            }
            small {
              ... on SanityMainImage {
                alt
                image {
                  asset {
                    fluid(maxWidth: 3600) {
                      ...GatsbySanityImageFluid
                    }
                  }
                }
              }
              ... on SanityVideo {
                videoFile {
                  asset {
                    url
                  }
                }
              }
            }
            _rawCopy
          }
          ... on SanityVideo {
            videoFile {
              asset {
                url
              }
            }
            previewImage {
              asset {
                url
              }
            }
            title
            minutes
            seconds
          }
          ... on SanityMainImage {
            alt
            image {
              asset {
                fluid(maxWidth: 3600) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          ... on SanityPullQuote {
            quote
            _rawAuthor
          }
          ... on SanityCopy {
            _rawCopyLeft
            _rawCopyRight
          }
          ... on SanityFacts {
            title
            fact {
              overline
              _rawCopy
            }
          }
          ... on SanityMail {
            title
          }
          ... on SanityDonateCta {
            ctaText
          }
          ... on SanityJournalCta {
            journalImage {
              alt
              image {
                asset {
                  fluid(maxWidth: 3600) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
            overline
            headline
            tags {
              options
            }
            copy
            slug
          }
          ... on SanityCopyCta {
            overline
            headline
            link {
              ctaText
              pageLink
            }
          }
          ... on SanityImageCta {
            image {
              alt
              image {
                asset {
                  fluid(maxWidth: 3600) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
            overline
            copy
            link {
              ctaText
              pageLink
            }
          }
        }
        shortcuts {
          firstImage {
            alt
            image {
              asset {
                fluid(maxWidth: 3600) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          firstLink {
            ctaText
            pageLink
          }
          secondImage {
            alt
            image {
              asset {
                fluid(maxWidth: 3600) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          secondLink {
            ctaText
            pageLink
          }
        }
      }
    }
  }
`;

TeamMalawiPage.propTypes = {
  data: PropTypes.object,
};

export default TeamMalawiPage;
